export const LANDING = '/landing';
export const ABOUT = '/About';
export const SIGN_UP = '/signup';
export const SIGN_UP_VERIFIED = '/signup/verified';
export const LOGIN = '/login';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';  
export const SIGNUP_EMAIL = '/SUemail'; 
export const CONTRACTOR_ACTIVE_JOBS = '/contractoractivejobs';
export const MY_PROFILE = '/myprofile';
export const POSTING = '/PostingPage/:postingId';
export const ACTIVEPOSTING = '/ActivePosting/:activeId';
export const COMPLETEDPOSTING = '/CompletedPosting/:completedId';
export const UNASSIGNEDPOSTING = '/PostingPage/:unassignedId';
export const PENDINGPOSTING = '/PendingPosting/:unassignedId';
export const PROFILE = '/Profile/:contractorId';
export const CLIENTPROFILE = '/ClientProfile/:clientId';
export const MAPS = '/maps';
export const CONTRACTOR_EXPLORE = '/contractorexplore'; 
export const HOMEOWNER_EXPLORE = '/homeownerexplore'; 
export const INBOX = '/InboxPage';
export const REVIEW = '/Review';
export const ADDLISTING = '/AddListing';
export const ACTIVEPOP = '/activepop';
export const SEARCHRESULTS = '/SearchResults';
export const HOMEOWNER_ACTIVE_JOBS = '/homeowneractivejobs';
export const INVOICE = '/Invoice/:id';
// export const INVOICEPREVIEW = '/invoicePreview';

